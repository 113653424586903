import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Featured from '../Featured/Featured'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

export default class NieuwsBerichtTemplate extends React.Component {
  render() {
    const { title, content, date, extra, featuredImage } = this.props
    const { urlActie, ctaButtonText } = extra

    return (
      <section className="section-post">
        <div className="container content">
          <div className="columns is-horizontal-center">
            <div className="column is-9">
              <Link to="/nieuws/">
                <FontAwesomeIcon
                  style={{
                    color: '#888',
                    'padding-right': '10px',
                  }}
                  icon={faArrowLeft}
                />
                Terug naar nieuwsoverzicht
              </Link>
              <h1 className="title">{title}</h1>
              <div className="date pb-4 ">{date}</div>
              {featuredImage ? <Featured src={featuredImage} /> : null}
              <div dangerouslySetInnerHTML={{ __html: content }} />
              {urlActie ? (
                <div className="has-text-centered">
                  <a
                    className="button has-text-white is-medium is-danger mt-5"
                    href={urlActie}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {ctaButtonText
                      ? ctaButtonText
                      : 'Profiteer nu van de korting'}
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

NieuwsBerichtTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
}
