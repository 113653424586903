import React from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import LinkCloud from '../components/LinkCloud/LinkCloud'
import SocialSharing from '../components/SocialSharing/SocialSharing'
import NieuwsBerichtTemplate from '../components/NieuwsBerichtTemplate/NieuwsBerichtTemplate'

const NieuwsBericht = ({ data }) => {
  const { wpNieuwsbericht: post, site } = data
  const pageTitle = post.seo?.metaTitle ? post.seo.metaTitle : post.title
  const desc = post.seo?.metaDescription
  const { title: siteTitle, siteUrl } = site.siteMetadata
  const { bigSquareAside } = site.siteMetadata.settings
  const shareUrl = `${siteUrl}/nieuws/${post.slug}`

  return (
    <Layout>
      <Helmet>
        <title>{`${pageTitle} | Nieuws | ${siteTitle}`}</title>
        <link rel="canonical" href={`${shareUrl}/`} />
        <meta name="description" content={desc} />
        <meta name="og:title" content={`${pageTitle} | Pretparkvoordeel.be`} />
        <meta name="og:description" content={desc} />
        <meta name="og:image" content="{post.seo.ogImage}" />
      </Helmet>
      <NieuwsBerichtTemplate {...post} bigSquareAside={bigSquareAside} />
      <SocialSharing shareUrl={shareUrl} />
      <LinkCloud />
    </Layout>
  )
}

export default NieuwsBericht

export const pageQuery = graphql`
  fragment NewsListFields on WpNieuwsbericht {
    id
    title
    content
    date(formatString: "DD MMMM YYYY", locale: "nl-BE")
    slug
    extra {
      urlActie
      ctaButtonText
      upfront
      banner
      qrCode
    }
    featuredImage {
      node {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, width: 800)
          }
        }
      }
    }
  }
  query NieuwsBerichtByID($id: String!) {
    wpNieuwsbericht(id: { eq: $id }) {
      id
      title
      slug
      content
      date(formatString: "DD MMMM YYYY", locale: "nl-BE")
      categories {
        nodes {
          name
          slug
        }
      }
      tags {
        nodes {
          name
          slug
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED, width: 800)
            }
          }
        }
      }
      extra {
        urlActie
        ctaButtonText
      }
      seo {
        metaDescription
        metaTitle
        ogImg: ogImage
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
        settings {
          bigSquareAside
        }
      }
    }
  }
`
